<template>
  <div class="tw-w-full tw-flex">
    <v-container style="max-width: 75rem" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-8">
  <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 2vh">
    <Logo/>
  </div>
  <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 5vh">
    <v-card  class="tw-flex tw-w-full card lg:card" elevation="0">
      <p class="account-header"
         :style="[$vuetify.breakpoint.smAndDown ?{marginTop:'3vh'}:{marginTop: '0vh'}]">Reset your password</p>
      <p class="desc">We will send you a link to reset your password
        to your email address</p>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }" >
        <form @submit.prevent="handleSubmit(resetPassword)">
          <div style="display: flex; flex-direction: column; width: 100%; height: auto;">
            <label class="mb-2 label">Email</label>
            <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }">
              <v-text-field
                  outlined
                  style="border-radius: 10px"
                  color="#FF7A01"
                  height="44"
                  v-model="email"
                  id="email"></v-text-field>
              <span class="err">{{ errors[0] ? 'must be a valid email' : '' }}</span>
            </ValidationProvider>
          </div>
          <v-btn rounded elevation="0" class="tw-mt-5 tw-w-full" color="#FF7A01" height="42" style="background-color: #FF7A01;
              color: white" :loading="loading" :disabled="invalid" @click="resetPassword">SEND RESET LINK TO EMAIL</v-btn>
        </form>
      </ValidationObserver>
    </v-card>
  </div>
</v-container>
  </div>
</template>

<script>
import Logo from "@/reuseable/Logo";
import {resetPassword} from "@/services/auth-services";
export default {
  name: "ResetPassword",
  components:{
    Logo

  },
  data(){
    return{
      email : '',
      value: '',
      loading: false
    }
  },
  methods:{
    async resetPassword(){
      try{
        this.loading = true
        await this.$store.dispatch('onboarding/resetPassword', {email:this.email})
        // resetPassword(this.email).then(res=>{
        //
        // })
        this.$displaySnackbar({
          message:'Reset email sent! Please check your email.',
          success: true
        })
        await this.$router.push('/login')
      }
      catch (err){
        this.$displaySnackbar({
          message:err.response.data.detail,
          success: false
        })
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card{
  display: flex;
  width: 450px;
  height: 440px;
  border: 1px solid #EAEAEA;
  border-radius: 32px;
  flex-direction: column;
  padding: 3% 3%;
  height: 87vh;

}
.card-mb{
  border: 1px solid #EAEAEA;
  border-radius: 32px;
  padding: 5%;
}
.account-header{
  color: #292929;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 768px){
    display: flex;
    width: 100%;
    justify-content: center;

  }
}
.desc{
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #818181;
}
.err {
  display: block;
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}
</style>